var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods"},[_c('div',{staticClass:"title"},[_c('el-form',{attrs:{"inline":true,"model":_vm.searchForm}},[_c('el-form-item',{attrs:{"label":"选择组织"}},[_c('HaiLiTree',{staticStyle:{"width":"300px"},attrs:{"clearable":"","extra":{ isContainFunder: 2 }},model:{value:(_vm.searchForm.unitNo),callback:function ($$v) {_vm.$set(_vm.searchForm, "unitNo", $$v)},expression:"searchForm.unitNo"}})],1),_c('el-form-item',{attrs:{"label":"商品类型"}},[_c('el-select',{ref:"searchFormType",attrs:{"clearable":""},model:{value:(_vm.searchForm.type),callback:function ($$v) {_vm.$set(_vm.searchForm, "type", $$v)},expression:"searchForm.type"}},_vm._l((_vm.GOODS_TYPE),function(opt){return _c('el-option',{key:opt.value,attrs:{"value":opt.value,"label":opt.label}})}),1)],1),_c('el-form-item',{attrs:{"label":"创建时间","prop":"time"}},[_c('el-date-picker',{staticStyle:{"width":"300px"},attrs:{"clearable":"","value-format":"yyyy-MM-dd HH:mm:ss","type":"datetimerange","range-separator":"至","start-placeholder":"开始时间","end-placeholder":"结束时间","align":"right"},model:{value:(_vm.searchForm.time),callback:function ($$v) {_vm.$set(_vm.searchForm, "time", $$v)},expression:"searchForm.time"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goAddGoods}},[_vm._v("新增")])],1)],1)],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":row.schemes}},[_c('el-table-column',{attrs:{"label":"方案"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.commodityName)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"押金","prop":"cashPledge"}}),_c('el-table-column',{attrs:{"label":"单期租金","prop":"singleRent"}}),_c('el-table-column',{attrs:{"label":"期数","prop":"periods"}}),_c('el-table-column',{attrs:{"label":"有效时长","prop":"validDay"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"agentName","label":"代理商"}}),_c('el-table-column',{attrs:{"prop":"storeName","label":"门店"}}),_c('el-table-column',{attrs:{"prop":"commodityType","label":"商品类型","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$changeObj(_vm.GOODS_TYPE)[row.commodityType])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"specification","label":"商品规格"}}),_c('el-table-column',{attrs:{"prop":"num","label":"方案数量"}}),_c('el-table-column',{attrs:{"prop":"status","label":"商品状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$changeObj(_vm.GOODS_STATUS)[row.status])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间"}}),_c('el-table-column',{attrs:{"prop":"city","fixed":"right","width":"200","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.changeGoodsStatus(row)}}},[_vm._v("切换状态")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.delGoods(row)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"fr mt20"},[_c('el-pagination',{attrs:{"current-page":_vm.beginIndex,"page-size":_vm.pageSize,"page-sizes":[10, 20, 30, 50, 100, 150, 200],"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.beginIndex=$event},"update:current-page":function($event){_vm.beginIndex=$event},"size-change":_vm.handleSizeChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }