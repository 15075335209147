<template>
  <div class="addGoods">
    <el-form
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="代理商/门店" prop="unitNo">
        <HaiLiTree
          v-model="form.unitNo"
          :selectKey="slectUnitNos"
          :extra='{isContainFunder: 2}'
          style="width: 300px"

          placeholder="请选择代理商/门店"
        >
        </HaiLiTree>
      </el-form-item>
      <el-form-item label="商品类型" prop="commodityType">
        <el-select
          v-model="form.commodityType"
          style="width: 300px"
          placeholder="请选择商品类型"
          @change="getType"
        >
          <el-option
            v-for="opt in GOODS_TYPE"
            :label="opt.label"
            :key="opt.value"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品规格" prop="specification">
        <el-input
          v-model="form.specification"
          style="width: 300px"
          placeholder="请输入商品规格"
          :maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品状态" prop="status">
        <el-select
          v-model="form.status"
          style="width: 300px"
          placeholder="请选择商品状态"
        >
          <el-option
            v-for="opt in GOODS_STATUS"
            :label="opt.label"
            :key="opt.value"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div>
      <h3 style="background-color: #fff" class="mt20 mb20">关联产品信息</h3>
      <el-table
        @selection-change="handleSelectionChange"
        :data="productList"
        style="width: 100%"
        ref="multipleTable"
        :row-key="getRowKeys"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column prop="productNo" label="产品编号"> </el-table-column>
        <el-table-column prop="productModel" label="产品名称">
        </el-table-column>
        <el-table-column prop="productType" label="产品类型" width="130">
          <template slot-scope="{ row }">
            {{ $changeObj(PRODUCT_TYPE)[row.productType] }}
          </template>
        </el-table-column>
        <el-table-column  prop="electricVoltage" label="电池电压">
          <template slot-scope="{row}" v-if="row.electricVoltage">
            {{row.electricVoltage}}V
          </template>
        </el-table-column>
        <el-table-column prop="batteryCapacity" label="电池容量">
           <template slot-scope="{row}" v-if="row.batteryCapacity">
            {{row.batteryCapacity}}Ah
          </template>
        </el-table-column>
        <el-table-column
          prop="bmsSupplierName"
          label="BMS供应商名称"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="gpsSupplierName"
          label="GPS供应商名称"
          min-width="150"
        >
        </el-table-column>
      </el-table>
    </div>
    <h3 class="mt20 bgf" v-if="schemeList.length">方案</h3>
    <el-button type="primary" class="mt20" v-else @click="addScheme"
      >添加方案</el-button
    >
    <div class="scheme">
      <div v-for="(scheme, index) in schemeList" :key="index" class="df">
        <el-form  :inline="true" >
          <el-form-item label="方案名称">
            <el-input
              v-model="scheme.commodityName"
              style="width: 170px"
              placeholder="请输入方案名称"
              :maxlength="10"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="押金">
            <el-input
              v-money
              v-model="scheme.cashPledge"
              style="width: 170px"
              placeholder="请输入押金"
            ></el-input>
          </el-form-item>
          <el-form-item label="单期租金">
            <el-input
              v-money
              v-model="scheme.singleRent"
              style="width: 170px"
              placeholder="请输入单期租金"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效时长">
            <el-input
              max="999"
              @input="validDayHandel(index)"
              type="number"
              v-number
              :min="1"
              v-model="scheme.validDay"
              style="width: 130px"
              placeholder="请输入有效时长"
            ></el-input>
            <span class="ml10">天</span>
          </el-form-item>
          <!--  -->
          <el-form-item label="期数">
            <el-input
              @input="periodsHandle(index)"
              max="999"
              class="form_container"
              type="number"
              v-number
              clearable
              :min="1"
              v-model="scheme.periods"
              style="width: 130px"
              :placeholder="scheme.periodsPlaceholder"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn mt10">
          <i
            class="el-icon-circle-plus-outline curp"
            v-if="schemeList.length < 4"
            @click="addScheme"
          ></i>
          <i class="el-icon-remove-outline curp" @click="delScheme(index)"></i>
        </div>
      </div>
    </div>
    <div style="margin: 50px 0 0 0;">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { GOODS_TYPE, GOODS_STATUS, PRODUCT_TYPE } from "@/js/constant";
import { addOrUpdateCommodity, queryCommodityDetail } from "@/api/goods";
import { getProductList } from "@/api/product";
import HaiLiTree from "@/components/HaiLiTree";
export default {
  data() {
    return {
      form: {
        unitNo: "",
        commodityType: 1,
        specification: "",
        status: 1
      },
      rules: {
        unitNo: [
          { required: true, message: "请选择代理商/门店", trigger: "change" }
        ],
        commodityType: [
          { required: true, message: "请选择商品类型", trigger: "change" }
        ],
        specification: [
          { required: true, message: "请输入商品规格", trigger: "blur" }
        ],
        status: [{ required: true, message: "请输入商品规格", trigger: "blur" }]
      },
      disaled: false,
      GOODS_TYPE,
      GOODS_STATUS,
      PRODUCT_TYPE,
      /** 代理商 */
      agentList: [],
      /*关联产品*/
      productList: [],
      /** 选择的产品 */
      selectProductList: [],
      /** 方案 */
      schemeList: [],
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      slectUnitNos: ""
    };
  },
  mounted() {
    if (this.$route.query.type != "edit") {
      this.getProductList(this.form.commodityType);
    }
    if (this.$route.query.type == "edit") {
      this.queryCommodityDetail();
    }
  },
  methods: {
    // 不能超过3位
    periodsHandle(index){
      this.schemeList[index].periods.length > 3 ? this.schemeList[index].periods = this.schemeList[index].periods.slice(0,3) : '';
    },
    validDayHandel(index){
      if(this.schemeList[index].validDay.length>3){
        this.schemeList[index].validDay=this.schemeList[index].validDay.slice(0,3)
        }
    },
    /** 获取详情 */
    async queryCommodityDetail() {
      const params = {
        commodityNo: this.$route.query.commodityNo
      };
      const res = await queryCommodityDetail(params);
      let detail = res.mdata.commodityDetail;
      // let unitNo = detail.unitNo;
      this.slectUnitNos = detail.unitNo;
      this.form.commodityType = detail.commodityType;
      this.form.specification = detail.specification;
      this.form.status = detail.status;
      this.selectProductList = detail.productNos;
      detail.schemes.forEach(el => {//如果periods为空，展示“无限期”
        !el.periods ? el.periodsPlaceholder = '无期限' : '';
      });
      this.schemeList = detail.schemes;
      const productParams = {
        beginIndex: 0,
        pageSize: 500,
        productType: this.form.commodityType
      };
      const productRes = await getProductList(productParams);
      this.productList = productRes.ldata;
      const tabList = [];
      this.productList.forEach(el => {
        this.selectProductList.forEach(el2 => {
          if (el.productNo == el2) {
            tabList.push(el);
          }
        });
      });
      tabList.forEach(el => {
        this.$refs.multipleTable.toggleRowSelection(el);
      });
    },
    // 确定按钮
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.schemeList.length) {
            this.$message.error("请添加方案");
            return;
          }
          let flag = false;
          try{
            flag = this.schemeList.find(el=>{
              if(!el.commodityName){
                this.$message.warning('请输入方案名称');
                return true;
              }
              if(!el.cashPledge){
                this.$message.warning('请输入押金');
                return true;
              }
              if(!el.singleRent){
                this.$message.warning('请输入单期租金');
                return true;
              }
              if(+el.validDay <= 0){
                this.$message.warning('有效时长大于0');
                return true;
              }
            })
          }catch(e){

          }
          if(flag){
            return;
          }
          this.disaled = true;
          const unitNo = this.form.unitNo[this.form.unitNo.length - 1];
          const params = {
            ...this.form,
            unitNo,
            type: 3
          };
          params.productNos = this.selectProductList;
          this.schemeList.forEach(el => {
            if(el.periods === '0'){
              el.periods = '';
            }
          });
          params.schemes = this.schemeList;
          if(this.$route.query.commodityNo) {
            params.commodityNo = this.$route.query.commodityNo;
          }
          try {
            const res = await addOrUpdateCommodity(params);
            if(res.message){
              this.$alert(res.message,'提示').then(()=>{
                this.$router.push("/leaseholdMgmt/goods");
              })
            }else{
              this.$message.success("操作成功");
              setTimeout(() => {
                this.$router.push("/leaseholdMgmt/goods");
              }, 1000);
            }
          } catch (e) {
            console.log(e);
            if(e.message){
              this.$alert(e.message,'提示')
            }else{
              this.$message.error('编辑失败')
            }
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/goods');
    },
    /**获取产品列表 */
    async getProductList(productType) {
      const params = {
        beginIndex: 0,
        pageSize: 500,
        productType
      };
      const res = await getProductList(params);
      this.productList = res.ldata;
    },
    /** 切换 获得产品列表 */
    getType() {
      this.getProductList(this.form.commodityType);
    },
    handleSelectionChange(val) {
      this.selectProductList = val.map(ele => ele.productNo);
    },
    getRowKeys(row) {
      return row.productNo;
    },
    // 添加方案
    addScheme() {
      this.schemeList.push({
        validDay: "1",
        singleRent: "",
        cashPledge: "",
        commodityName: ""
      });
    },
    delScheme(index) {
      this.schemeList.forEach((el, i, arr) => {
        if (i == index) {
          arr.splice(i, 1);
        }
      });
    }
  },
  components: {
    HaiLiTree
  }
};
</script>

<style scoped lang="less">
.addGoods {
  padding-bottom: 100px;
  .scheme {
    i {
      font-size: 28px;
      margin-left: 10px;
    }
  }
}
 .form_container /deep/input::-webkit-input-placeholder {
  color: black;
}
 .form_container /deep/input:-moz-placeholder {
  color: black;
}
 .form_container /deep/input::-moz-placeholder {
  color: black;
}
 .form_container /deep/input:-ms-input-placeholder {
  color: black;
}


</style>
