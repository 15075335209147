<template>
  <div class="goods">
    <div class="title">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="选择组织">
          <HaiLiTree
            clearable
            :extra="{ isContainFunder: 2 }"
            style="width: 300px"
            v-model="searchForm.unitNo"
          >
          </HaiLiTree>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select ref="searchFormType" v-model="searchForm.type" clearable>
            <el-option
              v-for="opt in GOODS_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="time">
          <el-date-picker
            v-model="searchForm.time"
            clearable
            style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="goAddGoods">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <el-table :data="row.schemes" style="width: 100%">
              <el-table-column label="方案">
                <template slot-scope="scope">
                  <!-- {{ scope.$index + 1 }} -->
                  {{ scope.row.commodityName }}
                </template>
              </el-table-column>
              <el-table-column label="押金" prop="cashPledge"></el-table-column>
              <el-table-column
                label="单期租金"
                prop="singleRent"
              ></el-table-column>
              <el-table-column label="期数" prop="periods"></el-table-column>
              <el-table-column
                label="有效时长"
                prop="validDay"
              ></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="agentName" label="代理商"> </el-table-column>
        <el-table-column prop="storeName" label="门店"> </el-table-column>
        <el-table-column prop="commodityType" label="商品类型" width="200">
          <template slot-scope="{ row }">
            {{ $changeObj(GOODS_TYPE)[row.commodityType] }}
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="商品规格">
        </el-table-column>
        <el-table-column prop="num" label="方案数量"> </el-table-column>
        <!-- <el-table-column
          label="押金"
          width="100"
        >
          <template slot-scope="{row}">
            {{row.schemes[0].cashPledge}}
          </template>
        </el-table-column>
        <el-table-column
          prop="validDay"
          label="单期租金"
          width="100"
        >
          <template slot-scope="{row}">
            {{row.schemes[0].singleRent}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="validDay"
          label="有效时长"
          width="200"
        >
          <template slot-scope="{row}">
            {{row.schemes[0].validDay}}
          </template>
        </el-table-column> -->
        <el-table-column prop="status" label="商品状态">
          <template slot-scope="{ row }">
            {{ $changeObj(GOODS_STATUS)[row.status] }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>

        <el-table-column prop="city" fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="changeGoodsStatus(row)"
              >切换状态</el-button
            >
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="delGoods(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GOODS_TYPE, GOODS_STATUS } from "@/js/constant";
import { getGoodsList, changeGoodsStatus, delGoods } from "@/api/goods";
import HaiLiTree from "@/components/HaiLiTree";

export default {
  data() {
    return {
      /** 查询 */
      searchForm: {
        unitNo: [],
        time: "",
        type: ""
      },
      beginTime: "",
      endTime: "",
      GOODS_TYPE,
      GOODS_STATUS,
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      }
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormType.blur()
        _this.onSearch()
      }
    };
    this.onSearch();
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === "/leaseholdMgmt/addGoods") {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  activated() {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false; // 重置详情页标识isBack
    }
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getGoodsList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getGoodsList();
    },
    /*获取列表 */
    async getGoodsList() {
      if (this.searchForm.time) {
        this.beginTime = this.searchForm.time[0];
        this.endTime = this.searchForm.time[1];
      } else {
        this.beginTime = null;
        this.endTime = null;
      }
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        pageSize: this.pageSize,
        beginIndex: page,
        beginTime: String(Date.parse(this.beginTime)),
        endTime: String(Date.parse(this.endTime))
      };
      if (!this.endTime || !this.beginTime) {
        params.beginTime = null;
        params.endTime = null;
      }
      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
      params.unitNo = unitNo;
      delete params.time;
      const res = await getGoodsList(params);
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    /** 切换状态 */
    async changeGoodsStatus(row) {
      const params = {
        commodityNo: row.commodityNo,
        status: row.status == 1 ? 2 : 1
      };
      await changeGoodsStatus(params);
      this.$message.success("操作成功");
      this.getGoodsList();
    },
    goAddGoods() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/lease/addOrUpdateCommodity' ).then((flag) => {
        if(flag) {
         this.$router.push("/leaseholdMgmt/addGoods");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {

        this.$router.push("/leaseholdMgmt/addGoods");
      })

    },
    /** 编辑 */
    goEdit(row) {
       this.$store.dispatch('login/checkHandle', '/managementPlatform/lease/addOrUpdateCommodity' ).then((flag) => {
        if(flag) {
          // 代理商
          const query = {
            commodityNo: row.commodityNo,
            type: "edit"
          };
          this.$router.push({
            path: "/leaseholdMgmt/addGoods",
            query
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
         // 代理商
        const query = {
          commodityNo: row.commodityNo,
          type: "edit"
        };
        this.$router.push({
          path: "/leaseholdMgmt/addGoods",
          query
        });
      })

    },
    /** 删除 */
    delGoods(row) {
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            commodityNo: row.commodityNo
          };
          await delGoods(params);
          this.$message.success("删除成功");
          this.onSearch();
        })
        .catch(() => {});
    }
  },
  components: {
    HaiLiTree
  }
};
</script>

<style scoped lang="less">
.goods {
  padding-bottom: 80px;
}
</style>
